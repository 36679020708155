import React from 'react';

import '../../assets/css/custom.css';

export default function Home() {
    return (
    <>
        <div className="main force-background">
            <div className="container custom-container">
                <div className="row justify-content-md-center">
                    <div className="col-md-4">
                        <h3 className="text-left custom-title"><strong>Objetivo</strong></h3>
                        <p className="description text-left">
                            A Textiltec Tecidos para Decoração, tem como objetivo resgatar e valorizar o que há de melhor em tecidos.<br />
                            Temos como prioridade atender nossos amigos clientes com muita ética, eficiência e rapidez na entrega.<br />
                            Representando grandes Indústrias Nacionais, dispomos de um estoque próprio para melhor atendê-los.<br />
                        </p>
                    </div>
                    <div className="col-md-4">
                        <h3 className="custom-title text-left"><strong>Missão</strong></h3>
                        <p className="description text-left">
                            Vestir sua casa com muito requinte e bom gosto, trazendo alegria ao seu ambiente e proporcionar mais conforto para sua família.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}