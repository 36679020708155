import React from 'react';

import '../../assets/css/custom.css';
import '../../assets/css/icons.css';
import './index.css';

export default function About() {
    return (
    <>
        <div className="main force-background">
            <div className="container custom-container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="contatos text-left">
                            <h3 className="title text-center"><strong>Contatos</strong></h3>
                            <p className="description">
                                Estes são alguns meios de se comunicar conosco. 
                                Envie-nos uma mensagem ou e-mail com suas dúvidas, sugestões e/ou solicitações de orçamento.
                            </p>
                            <div className="contact-list">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h6 className="contact-info">
                                            <span className="title"><i className="fas fa-phone-alt"></i> Telefones:</span> 
                                            <span className="description">(11) 2501-2540</span><br />
                                            <span className="title"><i className="fas fa-phone-alt"></i> Telefones:</span> 
                                            <span className="description">(11) 2501-2541</span>
                                        </h6>
                                    </div>
                                    <div className="col-md-6">
                                        <h6 className="contact-info">
                                            <span className="title"><i className="fab fa-whatsapp icon-whatsapp"></i> Whatsapp:</span> 
                                            <span className="description">(11) 94027-0217</span><br />
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div className="contact-list">
                                <h5 className="custom-title no-bottom"><i className="far fa-envelope"></i> <strong>E-mails:</strong></h5>
                                <h6 className="contact-info no-top left-20">
                                    <span className="custom-title">Financeiro:</span> 
                                    <span className="description">financeiro@textiltec.com.br</span>
                                    <br />
                                    <span className="custom-title">Administrativo/Vendas:</span> 
                                    <span className="description">contato@textiltec.com.br</span>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="formulario">
                            <h3 className="title"><strong>Formulário</strong></h3>
                            <form>
                                <div className="row">
                                    <div className="col-md-10 mr-auto ml-auto">
                                        <input
                                            id="form_name"
                                            type="text"
                                            className="form-control"
                                            placeholder="Nome"
                                        />
                                    </div>
                                    <div className="col-md-10 mr-auto ml-auto">
                                        <input
                                            id="form_email"
                                            type="email"
                                            className="form-control"
                                            placeholder="E-mail"
                                        />
                                    </div>
                                    <div className="col-md-10 mr-auto ml-auto">
                                        <input
                                            id="form_tel"
                                            type="text"
                                            className="form-control"
                                            placeholder="Telefone"
                                        />
                                    </div>
                                    <div className="col-md-10 mr-auto ml-auto">
                                        <textarea
                                            id="form_message"
                                            className="form-control"
                                            rows="4"
                                            placeholder="Mensagem"
                                        ></textarea>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="margin-top-30">
                    <div className="row">
                        <div className="col-md-5 text-left">
                           <div className="group-description">
                                <h5 id="endereco" className="title"><i class="fas fa-map-marker-alt"></i> <strong>Endereço</strong></h5>
                                <p className="description">Rua Ibarra, 61 - Vila Friburgo - São Paulo, SP</p>
                                <p className="description">
                                CEP: 04781-070
                                </p>
                           </div>
                            <div className="group-description">
                                <h5 id="funcionamento" className="title">
                                    <i class="far fa-clock"></i> <strong>Funcionamento</strong>
                                </h5>
                                <p className="description">
                                    <span className="text-brown">Segunda a sexta:</span> 8:15 às 18 hs
                                </p>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3653.5859889571366!2d-46.70846548548811!3d-23.69075927226765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce4fd45c8311c5%3A0xd1a4948b9e73192f!2sR.%20Ibarra%2C%2061%20-%20Vila%20Friburgo%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2004781-070!5e0!3m2!1spt-BR!2sbr!4v1571929740445!5m2!1spt-BR!2sbr" title="map-location" width="100%" height="450" frameborder="0" allowfullscreen=""></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}